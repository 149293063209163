import { call, put, takeEvery } from 'redux-saga/effects';
import { NotificationManager } from 'src/components/common/react-notifications';
import {
  AUTH_LOAD_INFO,
  AUTH_LOGOUT,
  LOGIN_INIT_FROM_LOCAL,
  LOGIN_STATUS_CHANGED,
  GET_PROFILE,
  GET_PROFILE_DONE,
} from '../actions';
import { tokenService } from '../../services/token.service';
import { profileService } from 'src/services/profile.service';

function* processLogout(action) {
  const { history } = action.payload;
  tokenService.removeUser();
  yield put({
    type: LOGIN_STATUS_CHANGED,
    payload: { login: false, role: null, active: false, username: null },
  });
  history.push('/auth/login');
}

function* loadAdminProfile(action) {
  // const profile = yield call(getAdminInfo);
  // // delete profile.password;
  // profile.password = '';
  // // console.log(profile);
  // yield put({ type: AUTH_INFO_LOADED, payload: profile });
}

function* initializeLoginFromLocal() {
  try {
    const accessToken = tokenService.parseAccessToken();
    // console.log('accessToken', accessToken);
    if (!accessToken) throw new Error('Login required');
    yield put({
      type: LOGIN_STATUS_CHANGED,
      payload: {
        login: true,
        role: accessToken['role'],
        active: !!accessToken['act'],
        username: accessToken['aud'],
      },
    });
  } catch (err) {
    const isInLoginPage = window.location.href.includes('/auth/login');
    if (isInLoginPage) return;

    const redirectToLogin = () => {
      if (!isInLoginPage) {
        window.location.href = '/auth/login';
      }
    };
    NotificationManager.error(
      err.message,
      'Authentication',
      3000,
      redirectToLogin
    );
    setTimeout(() => {
      redirectToLogin();
    }, 3000);
  }
}

function* getProfile() {
  const profile = yield call(profileService.get);
  // console.log('admin.profile', profile);
  yield put({ type: GET_PROFILE_DONE, payload: profile });
}

export default function* authSaga() {
  yield takeEvery(LOGIN_INIT_FROM_LOCAL, initializeLoginFromLocal);
  yield takeEvery(AUTH_LOGOUT, processLogout);
  yield takeEvery(AUTH_LOAD_INFO, loadAdminProfile);
  yield takeEvery(GET_PROFILE, getProfile);
}
